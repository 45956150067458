import jwt_decode from 'jwt-decode';
import { getApSwitch, getUserProfile } from '~/apis/user';
import { loginArvin } from '~/apis/login';
import { getInviterCode, postMessageEncrypt, getBrowserName, getOSVersion, getOS, windowPostLoginInfo, getQueryParams } from '~/composables/useUtils';
import { getGAParams } from '~/composables/useGA';

interface CredentialResponse {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
}

interface GoogleUserInfo {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  email: string;
  email_verified: boolean;
  nbf: number;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
  iat: number;
  exp: number;
  jti: string;
}

interface LoginRes {
  token: string;
  user_id: string;
  session: string;
}

declare const window: any;

export async function setLoginUser (params: LoginItf) {
  // device attrs To AP
  if (process.client) {
    const { getStoredUtmParams, defaultUtmParams } = useUtmTracking();
    const browserName = getBrowserName();
    const utmParams = getStoredUtmParams(defaultUtmParams);
    const { gaLoginPath: ga_login_path, gaClientId: ga_client_id, gaSessionId: ga_session_id } = getGAParams();
    params = {
      ...params,
      ...utmParams,
      ga_client_id,
      ga_login_path,
      ga_session_id,
      os_version: getOSVersion(),
      browsers: browserName === 'Unknown' ? 'unknown' : browserName,
      desktop_platform: getOS()
    };
  }
  let res: LoginRes = {} as LoginRes;
  try {
    res = await loginArvin(params);
  } catch (error:any) {
    if (error.code !== 40607 && error.code !== 40605) {
      ElMessage.error(error.msg);
    }
    return Promise.reject(error);
  }

  // window._paq && window._paq.push(['setUserId', res.user_id]); // matomo set user id

  setBpSessionToken(res.session);
  setUserInfo({
    userArvinToken: res.token
  });

  getApSwitch('', 0);
  const user: User = await getUserProfile();
  setUserInfo({
    userId: res.user_id,
    userName: user.user_name,
    avatarUrl: user.avatar,
    userArvinToken: res.token,
    ...user
  });
  const { gtag } = useGtag();
  gtag('set', 'user_id', res.user_id);

  const message = {
    userId: res.user_id,
    userArvinToken: res.token,
    userToken: res.session,
    avatarUrl: user.avatar,
    email: user.email,
    beInvitedCode: getInviterCode(),
    invited_status: user.invited_status,
    invite_path: user.invite_path,
    register_time: user.register_time,
    first_login_time: user.first_login_time
  };

  // const runtimeConfig = useRuntimeConfig();
  // const env = runtimeConfig.public.env as any;
  // window.postMessage(
  //   {
  //     type: 'ArvinSessionToken',
  //     msg: postMessageEncrypt(message),
  //     // Agreement to communicate with plug-ins
  //     host: env === 'production' ? 'tryarvin.com' : window.location.host,
  //     hideDrawer: true
  //   },
  //   '*'
  // );
  // window.postMessage({
  //   type: 'ArvinDeviceId',
  //   deviceId: getDeviceID()
  // }, '*');
  windowPostLoginInfo(message);

  // deleteInviterCode();
  reportEvent('User_Web_Login_Success');
  // const from = window.localStorage.getItem('login_from');
  // console.log('from', from);
  // if (from === 'extension') {
  //   window.localStorage.removeItem('login_from');
  //   window.close();
  // }
}

export function useGoogleLogin (callback: Function) {
  const loading = ref(false);
  const handleCredentialResponse = async (response: CredentialResponse) => {
    // const loading = ElLoading.service({
    //   lock: true,
    //   text: 'Login...',
    //   background: 'rgba(0, 0, 0, 0.7)',
    // })
    loading.value = true;
    const profile: GoogleUserInfo = jwt_decode(response.credential);
    console.log(profile);
    await setLoginUser({
      reg_way: 'google',
      access_token: response.credential
    });
    // loading.close();
    callback();
    setTimeout(() => {
      loading.value = false;
    }, 200);
  };

  const googleLoginInit = () => {
    const runtimeConfig = useRuntimeConfig();
    const { googleClientId: GOOGLE_CLIENT_ID } = runtimeConfig.public;
    window.google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse
    });

    window.google.accounts.id.renderButton(
      document.getElementById('googleIdSignIn'),
      {}
    );

    window.google.accounts.id.prompt();
  };

  return { googleLoginInit, loading };
}
